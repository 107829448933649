<template>
  <div>
    <TitleBar :title-stack="titleStack" />
    <HeroBar>
      プログラムグループ管理
      <div class="buttons" slot="right">
        <b-button
          icon-left="dock-window"
          type="is-info is-light"
          size="is-small"
          @click="willBackOrReplace({ name: 'Programs' })"
          >プログラム管理へ戻る</b-button
        >
      </div>
    </HeroBar>
    <section class="section is-main-section">
      <div class="is-relative" v-if="isLoading" style="min-height: 400px">
        <b-loading :is-full-page="false" :active="isLoading" />
      </div>
      <div class="columns is-multiline" v-else>
        <div
          class="column is-4"
          v-for="{ parentGroup, groupStructs } in parentGroupStructs"
          :key="parentGroup.id"
        >
          <div class="box">
            <article class="media">
              <figure class="media-left">
                <b-icon icon="tag" /><br />
                <b-tooltip
                  label="プール施設利用"
                  type="is-info"
                  v-if="parentGroup.poolFlag"
                >
                  <b-icon icon="water" type="is-info" />
                </b-tooltip>
              </figure>
              <div class="media-content">
                <div class="content">
                  <nav class="level is-mobile">
                    <div class="level-left">
                      <span class="level-item">
                        <strong>{{ parentGroup.name }}</strong>
                      </span>
                    </div>
                    <div class="level-right">
                      <span class="level-item">
                        <b-button
                          type="is-warning"
                          size="is-small"
                          icon-left="square-edit-outline"
                          @click.prevent="willEditParentGroup(parentGroup)"
                        />
                      </span>
                      <span class="level-item" v-if="groupStructs.length == 0">
                        <b-button
                          type="is-danger"
                          size="is-small"
                          icon-left="delete"
                          @click.prevent="willDeleteGroup(parentGroup)"
                        />
                      </span>
                    </div>
                  </nav>
                  <p>
                    <b-field grouped group-multiline>
                      <div
                        class="control"
                        v-for="{ group, programCount } in groupStructs"
                        :key="group.id"
                      >
                        <b-taglist attached>
                          <a
                            href="#"
                            @click.prevent="willEditGroup(parentGroup, group)"
                          >
                            <b-tag type="is-light">{{ group.name }}</b-tag>
                          </a>

                          <b-tag
                            type="is-light"
                            :closable="programCount == 0"
                            @close="willDeleteGroup(group)"
                            >{{ programCount }}
                          </b-tag>
                        </b-taglist>
                      </div>
                    </b-field>
                  </p>
                </div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a
                      class="level-item"
                      @click.prevent="willCreateGroup(parentGroup)"
                    >
                      <b-icon type="is-success" icon="plus-circle" />
                    </a>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </div>
        <div class="column is-4">
          <b-button icon-left="plus-circle" @click="willCreateParentGroup"
            >新規作成</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import { ApiProgram, ApiProgramGroup } from '@/api'
import { Collection } from '@/util'
import ProgramGroupFormVue from '@/components/ProgramGroups/ProgramGroupForm.vue'
export default {
  mixins: [BaseMixin],
  components: { HeroBar, TitleBar },
  computed: {
    titleStack () {
      return [
        'マスタ管理', 'プログラム', 'グループ'
      ]
    },
    childGroupMap () {
      return this.mProgramGroups.filter(row => row.parentId)
        .map(row => {
          return {
            group: row,
            programCount: this.mPrograms.filter(mProgram => mProgram.groupId === row.id).length
          }
        })
        .reduce((map, { group, programCount }) => {
          if (group.parentId in map) {
            map[group.parentId].push({ group, programCount })
          } else {
            map[group.parentId] = [{ group, programCount }]
          }
          return map
        }, {})
    },
    parentGroupStructs () {
      return this.mProgramGroups.filter(row => !row.parentId).map(row => {
        return {
          parentGroup: row,
          groupStructs: this.childGroupMap[row.id] || []
        }
      })
    },
  },
  data () {
    return {
      mPrograms: [],
      mProgramGroups: [],
    }
  },
  methods: {
    willCreateGroup (parentGroup) {
      this.openEdit(parentGroup, null)
    },
    willEditGroup (parentGroup, row) {
      this.openEdit(parentGroup, row)
    },
    willCreateParentGroup () {
      this.openEdit(null, null)
    },
    willEditParentGroup (row) {
      this.openEdit(null, row)
    },
    openEdit (parentGroup, row) {
      console.log('willToEdit')
      this.$buefy.modal.open({
        parent: this,
        component: ProgramGroupFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { parentGroup, mProgramGroup: row },
        events: {
          saved: (mProgramGroup) => {
            console.log(mProgramGroup, 'didSaved')
            this.mProgramGroups = new Collection(this.mProgramGroups).set(mProgramGroup).members
            this.alertSuccess(mProgramGroup ? '更新しました' : '作成しました')
          },
        }
      })
    },
    willDeleteGroup (group) {
      console.log(group, 'willDeleteGroup')
      ApiProgramGroup.deleteProgramGroup(group.id).then(() => {
        this.mProgramGroups = new Collection(this.mProgramGroups).remove(group.id).members
        this.alertSuccess('削除しました')
      }).catch(this.handleError)
    },
  },
  mounted () {
    this.isLoading = true
    setTimeout(() => {
      ApiProgram.fetchPrograms().then(({ mPrograms, mProgramGroups }) => {
        this.mPrograms = mPrograms
        this.mProgramGroups = mProgramGroups
        this.isLoading = false
      }).catch(this.handleError)
    }, 1000)
  }
}
</script>

<style>
</style>
