<template>
  <CardComponent :title="cardTitle" icon="ballot" style="width: 400px">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(willSave)">
        <b-field label="分類名">
          <ValidationInput
            v-model="programGroupForm.name"
            rules="required|max:100"
          ></ValidationInput>
        </b-field>
        <b-field label="プール施設" v-if="isParent">
          <b-checkbox v-model="programGroupForm.poolFlag">利用する</b-checkbox>
        </b-field>
        <hr />
        <b-field>
          <b-field grouped>
            <div class="control">
              <b-button
                native-type="submit"
                type="is-primary"
                :loading="isLoading"
                >登録</b-button
              >
            </div>
            <div class="control">
              <b-button
                type="is-primary is-outlined"
                @click="$emit('close')"
                :loading="isLoading"
                >キャンセル</b-button
              >
            </div>
          </b-field>
        </b-field>
      </form>
    </ValidationObserver>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { MProgramGroup, ProgramGroupForm } from '@/types/typeProgram'
import { ApiProgramGroup } from '@/api'
export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  props: {
    parentGroup: {
      type: MProgramGroup,
      default: null
    },
    mProgramGroup: {
      type: MProgramGroup,
      default: null
    },
  },
  computed: {
    isParent () {
      return !this.parentGroup
    },
    isCreate () {
      return !this.mProgramGroup
    },
    cardTitle () {
      if (this.isParent) {
        return this.mProgramGroup ? `大分類「${this.mProgramGroup.name}」 編集` : '大分類を新規作成'
      }
      return this.mProgramGroup ? `小分類「${this.mProgramGroup.name}」 編集` : `大分類「${this.parentGroup.name}」内に小分類を新規作成`
    },
  },
  data () {
    return {
      programGroupForm: new ProgramGroupForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiProgramGroup.saveProgramGroup(this.programGroupForm).then(({ mProgramGroup }) => {
          this.$emit('saved', mProgramGroup)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
  },
  mounted () {
    if (this.parentGroup) {
      this.programGroupForm.parentId = this.parentGroup.id
    }
    if (this.mProgramGroup) {
      this.programGroupForm.fill(this.mProgramGroup)
    }
  }
}
</script>
